.block {
  display: block;
}
.invoice-header{
  border: 3px solid black;
  padding-top: 5px;
}
.border-r-20{
  border-radius: 20px;
}
.flex {
  display: flex;
}

.w-auto {
  width: auto !important;
}

.w-100 {
  width: 100% !important;
}
.w-90 {
  width: 90% !important;
}
.p-20{
  padding: 20px 20px 20px 20px;
}
.p-ex-t-20{
  padding: 0 20px 20px 20px;
}

.w-50 {
  width: 50%;
}

.w-55 {
  width: 55%;
}

.w-45 {
  width: 45% !important;
}

.w-60 {
  width: 60% !important;
}
.w-70 {
  width: 70% !important;
}
.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-48 {
  width: 48% !important;
}

.w-17 {
  width: 17% !important;
}

.w-18 {
  width: 18% !important;
}

.row {
  position: relative;
  border-bottom: 1px solid $color-gray;
}

.row__remove {
  display: flex;
  position: absolute;
  right: -25px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  .row:hover &,
  .row:focus-within & {
    opacity: 1;
  }
}
.header-line-right{
  height: 52px;
  width: 6%;
  background-color: #fc7c5b;
  position: absolute;
  margin-top: 28px;
}

.header-line{
  height: 2px;
  background-color: #fc7c5b;
  margin-top: 79px;
  position: absolute;
}
.main-color{
  color: #1e1e1e;
  font-family: Shamel, serif;
  font-weight: bold;
}
.p-color{
  color: #000000;
  font-family: Shamel, serif;
  font-weight: bold;
}
.a-color{
  color: #151515;
}


.gray-color{
  color: #b7b7b7;
  font-family: Shamel, serif;
}
