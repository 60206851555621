@media (min-width: 1500px) and (max-width: 5000px) {

}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {

}


/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {

}


/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {

}

/* small mobile :320px. */
@media (max-width: 500px) {
    .app {
        margin: 100px auto 50px auto;
        width: 100%;
        height: 100%;
        font-family: Shamel, serif;
    }
    .div-invoice {
        margin: 30px auto 50px auto;
        width: 100%;
        height: 100%;
    }
    .header-line-right{
        height: 38px;
        width: 6%;
        background-color: #fc7c5b;
        position: absolute;
        margin-top: 28px;
    }
    .header-line{
        height: 2px;
        background-color: #fc7c5b;
        margin-top: 65px;
        position: absolute;
    }
    .ms-k{
        font-size: 13px;
        font-family: Arial, serif;
        position: absolute;
        margin-top: 28px;
        margin-right: 10px;
        color: #0464cc;
        font-weight: bold;
    }
    .ms-k-ar{
        font-size: 19px;
        font-family: Shamel, serif;
        position: absolute;
        margin-right: 10px;
        color: #0464cc;
        font-weight: bold;
    }
    .header-details {
        margin-top: 3px;
    }
    .header-acount-number {
        margin-right: 10%;
    }

}

